
export type GA = ((...args: any[]) => void) & {
  getAll(): GATracker[]
}

export interface GATracker {
  get(name: 'name'): string
}

declare global {
  interface Window {
    ga?: GA
    dataLayer?: any[]
  }
}

/**
 * This function sends a google analytics event, but waits until google analytics is ready and a tracker has been
 * defined.
 *
 * @param event The event to send.
 *
 * Event example:
 * ```
 * name: 'ministry_interest_form',
 * args: {
 * ministry: "ministry name",
 * }
 * ```
 */
export function gaSend(event: Record<string, any>) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'event': event.name,
    ...event.args,
  })
}

export function gaCustomEventButtonsInit() {
  // Look for any buttons marked to send plausible events.  When clicked, send the event to google analytics too.
  //
  // TODO: if we decide to go with Plausible, delete this.  If we decide to stay with GA4, update the class name
  $('[class*=plausible-event-name]').on('click', function() {
    const classes = this.className.split(/\s+/)
    const event = classes.find((cls) => cls.startsWith('plausible-event-name='))
    if (!event) return

    const eventName = event.split('=')[1]
    gaSend({ name: eventName, args: {} })
  })
}

export function gaContentGroupInit() {
  if ('WCC' in window && (window.WCC as any).gaContentGroup) {
    window.ga('set', 'contentGroup', (window.WCC as any).gaContentGroup)
  }
}
